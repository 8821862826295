<template>
  <div class="delete-pen">
    <backStageHeader title="删除点阵笔"></backStageHeader>
    <div class="delete-pen-box flex">
      <div>
        <div>
          <el-input class="delete-pen__search mg-r-12 mg-b-10" placeholder="请输入点阵笔编号" v-model="penCode">
            <i slot="prefix" class="iconfont icon-icon_sousuo pd-l-4"></i
          ></el-input>
          <el-button type="primary" @click="getPenData"><span class="pd-x-14">查询</span></el-button>
        </div>

        <div class="delete-pen__info inline-block" v-for="item in penDataList" :key="item.key">
          <span class="pen_info pen_info-school truncate" :title="item.schoolName">{{ item.schoolName }}</span>
          <span class="pen_info">{{ setPenTypeName(item.type) }}</span>
          <span class="pen_info pen_info-user truncate" :title="item.UserName">{{ item.UserName }}</span>
          <i class="iconfont icon-ic_cebianlan_shanchudianzhenbi" @click="handleDelete"></i>
        </div>

        <p class="delete-pen__hint mg-l-16">{{ hintContent }}</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @desc 删除点阵笔
 */
// components
import { Button, Input } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
// api
import { selectPenByNumber, deleteAllByNumber } from '@/api/back-stage';

const TYPE_STUDENT = '1';
const TYPE_PUBLIC = '2';
const TYPE_TEACHER = '3';

export default {
  name: 'delete-pen',
  components: {
    [Button.name]: Button,
    [Input.name]: Input,
    backStageHeader,
  },
  data() {
    return {
      penCode: '',
      penDataList: [],
      hintContent: '',
    };
  },
  methods: {
    // 设置点阵笔类型
    setPenTypeName(type) {
      switch (String(type)) {
        case TYPE_STUDENT:
          return '学生点阵笔';
        case TYPE_PUBLIC:
          return '公共点阵笔';
        case TYPE_TEACHER:
          return '教师点阵笔';
        default:
          return '';
      }
    },
    getPenData() {
      if (!this.penCode.trim()) {
        this.resetData();
        this.hintContent = '请输入点阵笔编号';
        return;
      }
      selectPenByNumber({
        number: this.penCode,
      }).then((res) => {
        this.penDataList = res.result || [];
        if (this.penDataList.length) {
          this.hintContent = '删除后该学校的该点阵笔将失效，请谨慎操作';
        } else {
          this.hintContent = '找不到该点阵笔';
        }
      });
    },
    handleDelete() {
      deleteAllByNumber({
        number: this.penCode,
      }).then(() => {
        this.$message.success('删除成功');
        this.resetData();
      });
    },
    resetData() {
      this.penDataList = [];
      this.hintContent = '';
    },
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
